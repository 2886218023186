import MainPage from "@/components/MainPage.vue";
import MainTitle from "@/components/MainTitle.vue";
import EntitySearch from "@/components/EntitySearch.vue";
import SellersTable from "@/components/Rating/SellersTable";
import { dateRangeFromDate, today, dateRangeBoundaries } from "@/components/RangeCalendar.vue";
import CalendarMixin from "@/mixins/Calendar";
export default {
  name: "Home",
  mixins: [CalendarMixin],
  meta() {
    return {
      title: this.$route.params.mp === "wb" ? "Аналитика и статистика продавцов маркетплейса Wildberries, анализ продаж конкурентов на Вайлдберриз" : "Аналитика и статистика продавцов маркетплейса OZON, анализ продаж конкурентов на ОЗОН",
      meta: [{
        name: "description",
        content: this.$route.params.mp === "wb" ? "SalesFinder - сервис детального анализа конкурентов Вайлдберриз: анализ продаж брендов, продавцов, товаров и категорий!" : "SalesFinder - сервис детального анализа конкурентов OZON: анализ продаж брендов, продавцов, товаров и категорий!"
      }]
    };
  },
  data() {
    return {};
  },
  components: {
    MainPage,
    MainTitle,
    EntitySearch,
    SellersTable
  },
  computed: {
    ratingBindings() {
      var _this$calendarBoundar;
      if (!this.calendarBoundaries) {
        return;
      }
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      const weekRange = dateRangeFromDate(lastAvailableDate, 30);
      const [date, date2] = dateRangeBoundaries(weekRange, this.calendarBoundaries);
      return {
        action: "rating/getRatingSellers",
        id: this._uid,
        routeParams: this.$route.params,
        reportSettings: {
          mp: this.$route.params.mp,
          metric: "revenue",
          date,
          date2,
          size: 20,
          ...this.$route.query
        },
        paginatable: false
      };
    },
    PlaceHolder() {
      return this.$route.params.mp == "wb" ? "Поиск по продавцам: название или ИНН или ОГРН" : "Поиск по продавцам: название или ОГРН или ИНН";
    }
  },
  methods: {
    searchLoader(query) {
      return this.$store.dispatch("seller/getSellerByName", {
        query,
        mp: this.$route.params.mp
      });
    },
    getRoute(item) {
      return {
        name: "SellerInfo",
        params: {
          s_id: item.s_id,
          mp: this.$route.params.mp
        }
      };
    }
  }
};