import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { DataTable, ColWidth } from "@/components/DataTable/DataTable";
import { getEntityTableColumns } from "@/utils/enums";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    NA: {
      type: String,
      default: "н/д"
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      if (this.small) {
        query.page_size = 5;
      }
      return this.$store.dispatch(this.action, {
        ...query,
        ...this.reportSettings
      });
    },
    href_s_id_BZ(item) {
      let href = "/" + (this.reportSettings.mp == "wb" ? "ozon" : "wb") + "/seller/" + item.s_id_BZ + "/info";
      return href;
    }
  },
  computed: {
    columns() {
      let preferred = this.reportSettings.metric;
      if (preferred === "revenue") {
        preferred = ["revenue", "sold"];
      }
      let columns = [{
        title: "№",
        position: 1,
        show: true,
        name: "index",
        width: 50,
        type: "number"
      }, {
        title: "Продавец",
        position: 2,
        show: true,
        name: "seller",
        width: ColWidth('seller'),
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "SellerInfo",
              params: {
                ...this.routeParams,
                s_id: item.s_id
              },
              query: {
                fbs: this.reportSettings.fbs,
                date: this.reportSettings.date,
                date2: this.reportSettings.date2
              }
            },
            text: item.seller,
            target: this.$route.name === "Rating" || this.$route.name === "Dashboard" ? "_blank" : null
          };
        }
      }, {
        //&: 2024-11-29: INN/OGRN
        title: "ИНН продавца",
        name: "s_inn",
        show: true,
        type: "slot",
        width: 140,
        filter: "numberRange"
      }, {
        title: "ОГРН продавца",
        name: "s_ogrn",
        show: true,
        type: "slot",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Продает на OZON и WB",
        name: "s_id_BZ",
        show: true,
        type: "slot",
        width: 120,
        filter: "numberRange"
      }, ...getEntityTableColumns("seller", 3, preferred)];
      if (this.small) {
        columns = columns.filter(item => item.name === "seller" || item.name === "revenue");
      }
      return columns;
    }
  }
};