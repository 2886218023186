import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main-page', {
    key: this.$route.params.mp
  }, [_c('main-title', [_vm._v(" Аналитика продаж по продавцам на " + _vm._s(_vm.$route.params.mp === "wb" ? "Wildberries" : "Ozon") + " ")]), _c('entity-search', {
    staticClass: "mt32",
    attrs: {
      "placeholder": _vm.PlaceHolder,
      "no-data-text": "Продавцы не найдены",
      "text-prop": "seller",
      "loader": _vm.searchLoader,
      "get-route": _vm.getRoute
    }
  }), _c('main-title', {
    staticClass: "mt64"
  }, [_vm._v(" Топ-20 продавцов по выручке за последние 30 дней ")]), _c('button', {
    staticClass: "btn-outline btn-ratings mt24",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'Rating',
          params: {
            type: 'sellers'
          }
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/rating.svg`),
      "width": "19",
      "height": "18"
    }
  }), _vm._v(" Все рейтинги ")]), _vm.ratingBindings ? _c('sellers-table', _vm._b({
    staticClass: "mt32"
  }, 'sellers-table', _vm.ratingBindings, false)) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };